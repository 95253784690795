<template>
  <div class="main-styles">
    <p>
      高尔夫球<br />
      一般规则<br />1、如果比赛场地有更改，所有注单将被视为无效。
    </p>
    <p>2、如果出现并列名次的情况，并列第一规则将运用于结算方式内。</p>
    <p>
      3、如果锦标赛在法定比赛时间前结束并且已经颁奖，所有注单将视为有效。在比赛结束后投注的注单将被视为无效，除非之后继续进行比赛并且对锦标赛的最终结果产生影响(淘汰赛/附加赛除外)。
    </p>
    <p>
      4、如果锦标赛被官方中断，所有注单将被视为无效，除非盘口结算方式已有明确结果。<br />
      <br />投注类型<br />冠军投注<br />1、预测哪位选手将会赢得锦标赛。
    </p>
    <p>2、任何不在名单中的选手"指的是所有不在冠军盘口列出的选手。</p>
    <p>
      3、所有冠军盘投注的结算将以锦标赛的获胜者为准，任何淘汰赛/附加赛也包括在内。
    </p>
    <p>
      <br />配对赛 /2球/3球(18洞)<br />1、预测哪位选手将以最少的杆数完成回合。
    </p>
    <p>
      2、盘口将两名选手配对在一起作为投注选项，但球员在实际比赛中未必是同组击球。
    </p>
    <p>3、优胜者则是以最少杆数打完18个洞的球员。</p>
    <p>4、如果投注的选手没有从第一回合参与比赛，所有注单将被视为无效。</p>
    <p>
      5、双方球员都必须完成18个洞。如果球员中有协议或因受伤理由让另一位球员提前结束比赛，所有的注单将被视为无效。
    </p>
    <p>6、2球赛事，盘口将提供平局投注选项。</p>
    <p>
      7、3球赛事，盘口不提供平局投注选项，若遇到打平的情况，并列第一规则的结算方式将运用于此。
    </p>
    <p>
      <br />配对赛 /2球/3球(72洞/锦标赛配对投注)<br />1、预测哪位选手将以最少的杆数完成锦标赛。
    </p>
    <p>
      2、盘口将两名选手配对在一起作为投注选项，但球员在实际比赛中未必是同组击球。
    </p>
    <p>3、优胜者是以最少杆数打完72个洞（淘汰赛/附加赛也包括在内）的选手。</p>
    <p>4、如果投注的选手没有从第一回合参与比赛，所有注单将被视为无效。</p>
    <p>
      5、锦标赛配对投注两方选手必须完成72个洞。如果球员中有协议或因受伤理由让另一位球员提前结束比赛，所有的注单将会被视为无效。
    </p>
    <p>6、2球赛事，盘口将提供平局投注选项。</p>
    <p>
      7、3球赛事，盘口不提供平局投注选项，若遇到打平的情况，并列第一规则的结算方式将运用于此。
    </p>
    <p>
      <br />下一个洞的分数-单个选手市场<br />1、预测选手在下一个洞可以获得的分数。（
      小鸟球或更好/ 标准杆/柏忌或更差 ）柏忌是指多标准杆数一杆。
    </p>
    <p>2、如果投注的选手没有参与投注球洞的击球，所有的注单将会被视为无效。</p>
    <p>
      3、一旦选手在规定的洞口开球，注单将被视为有效。如果玩家无法完成指定洞口（例如受伤离场），那么将会获取“超过标准杆数”奖励。
    </p>
    <p>4、投注的结算将依据相关机构（欧洲职业高尔夫球手协会）公布的赛果为准。</p>
    <p>
      <br />让分盘<br />1、预测哪位选手将以最少的杆数完成回合或比赛。在比赛正式开始前，
      一方选手已得到另一方让的虚拟分数，在领先的情况下开始比赛。如果在考量盘口指定的让分数后，双方球员获得同样的分数，比赛结果将视为打平，所有注单将被返还。
    </p>
    <p>
      <br />大/小盘（分数）<br />1、预测双方选手获得的最终比分将大于或小于在盘口指定的大/小盘分数。
    </p>
    <p>
      2、如果赛事中断，大/小盘将仅会结算任何将来的得分不会影响到赛果的注单。若遇到任何其它情况，注单将一律被视为无效。
    </p>
    <p>
      <br />前5名/前10名<br />1、预测在特定的锦标赛中投注的选手是否会列入比赛的前5/10名优胜者。
    </p>
    <p>2、并列第一规则的结算方式将运用于此盘口的注单。</p>
    <p>3、投注的结算皆以锦标赛结束后的最终赛果为准。</p>
    <p><br />净胜分数<br />1、预测冠军和第二名优胜者在比赛结束后的杆数差别。</p>
    <p>2、投注的结算皆以锦标赛结束后的最终赛果为准。</p>
    <p>3、如果出现附加赛，附加赛的杆数将不计算为比赛的最终结果。</p>
  </div>
</template>
